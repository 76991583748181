import ProductGrid from "../components/products/ProductGrid";
import peaShootProductImage from "../assets/productItemImages/peaShoots.png";
import arugulaProductImage from "../assets/productItemImages/arugula.png";
import radishProductImage from "../assets/productItemImages/radish2.png";
import broccoliProductImage from "../assets/productItemImages/brocolli.png";
import mixedProductImage from "../assets/productItemImages/mixed.png";
import styled from "styled-components";

const StripeButtonWrapper = styled.div`
  width: 200px !important;
`;

const products = [
  {
    id: "1",
    name: "Pea Shoots 50g",
    price: "$5",
    imageURL: peaShootProductImage,
    isOnSale: false,
    button: <StripeButtonWrapper>
      <stripe-buy-button
        buy-button-id="buy_btn_1OPDDWIv0qajsiXe9tkxTQBG"
        publishable-key="pk_live_51OJnCCIv0qajsiXeFjQ8nki2C0brOjov4sL6bRSLoHy1wJlIdwjmi2fm9EDCpbBWC3Ljd3usUfQb7PDVPG7oxWl400rxiUiULr" />
    </StripeButtonWrapper>
  },
  {
    id: "2",
    name: "Broccoli",
    price: "$5",
    imageURL: broccoliProductImage,
    isOnSale: false,
    button: (<stripe-buy-button
      buy-button-id="buy_btn_1OPTjTIv0qajsiXe4qmsGNQ3"
      publishable-key="pk_live_51OJnCCIv0qajsiXeFjQ8nki2C0brOjov4sL6bRSLoHy1wJlIdwjmi2fm9EDCpbBWC3Ljd3usUfQb7PDVPG7oxWl400rxiUiULr" />)
  },
  {
    id: "3",
    name: "Radish",
    price: "$5",
    imageURL: radishProductImage,
    isOnSale: false,
    button: (<stripe-buy-button
      buy-button-id="buy_btn_1OPTmLIv0qajsiXeXrljIyhB"
      publishable-key="pk_live_51OJnCCIv0qajsiXeFjQ8nki2C0brOjov4sL6bRSLoHy1wJlIdwjmi2fm9EDCpbBWC3Ljd3usUfQb7PDVPG7oxWl400rxiUiULr" />)
  },
  {
    id: "4",
    name: "Arugala",
    price: "$5",
    imageURL: arugulaProductImage,
    isOnSale: false,
    button: (<stripe-buy-button
      buy-button-id="buy_btn_1OPTnsIv0qajsiXeQBsLvKkK"
      publishable-key="pk_live_51OJnCCIv0qajsiXeFjQ8nki2C0brOjov4sL6bRSLoHy1wJlIdwjmi2fm9EDCpbBWC3Ljd3usUfQb7PDVPG7oxWl400rxiUiULr" />)
  },
  {
    id: "5",
    name: "Momo MicroMix",
    price: "$5",
    imageURL: mixedProductImage,
    isOnSale: false,
    button: (<stripe-buy-button
      buy-button-id="buy_btn_1OPTr8Iv0qajsiXe9kTaflSW"
      publishable-key="pk_live_51OJnCCIv0qajsiXeFjQ8nki2C0brOjov4sL6bRSLoHy1wJlIdwjmi2fm9EDCpbBWC3Ljd3usUfQb7PDVPG7oxWl400rxiUiULr" />)
  }
];

const Products = () => <ProductGrid products={products} />;

export default Products;

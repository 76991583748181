import React, { useState } from "react";
import { Box, Grid, Image, Text, useDisclosure } from "@chakra-ui/react";
import RecipeModal from "./RecipeModal";
import { recipes } from "./recipeData";

const RecipesGrid = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
    onOpen();
  };

  return (
    <Box p={4}>
      <Grid templateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap={6}>
        {recipes.map((recipe) => (
          <Box
            key={recipe.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            onClick={() => handleRecipeClick(recipe)}
            cursor="pointer"
          >
            <Image src={recipe.image} alt={recipe.name} />
            <Box p={4}>
              <Text fontWeight="bold" fontSize="xl">
                {recipe.name}
              </Text>
            </Box>
          </Box>
        ))}
      </Grid>

      {selectedRecipe && (
        <RecipeModal
          isOpen={isOpen}
          onClose={onClose}
          recipe={selectedRecipe}
        />
      )}
    </Box>
  );
};

export default RecipesGrid;

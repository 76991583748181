import { AddIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Image,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

const ProductItem = ({ product }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const imageSize = useBreakpointValue({ base: "150px", md: "150px" });
  const paddingSize = useBreakpointValue({ base: "3", md: "4" });
  return (
    <Box
    width={{ base: "100%", md: "100%" }}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bgColor={bgColor}
    >
      <Image
        src={product.imageURL}
        alt={product.name}
        objectFit="cover"
        height={imageSize}
        width={"100%"}
      />

      <Box p={paddingSize}>
        <Box d="flex" alignItems="baseline">
          {product.isOnSale && (
            <Badge borderRadius="full" px="2" colorScheme="teal">
              Sale
            </Badge>
          )}
        </Box>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {product.name}
        </Box>

        <Box>
          {product.price}
          <Box as="span" color="gray.600" fontSize="sm">
            / unit
          </Box>
        </Box>

        <Box  mt="2" >
          {product.button}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductItem;

// use stat in chakra ui for price

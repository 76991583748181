import recipe1 from "../../assets/recipes/recipe1.webp";
import recipe2 from "../../assets/recipes/recipe2.webp";
import recipe3 from "../../assets/recipes/recipe3.webp";
import recipe4 from "../../assets/recipes/recipe4.webp";
import recipe5 from "../../assets/recipes/recipe5.webp";
import recipe6 from "../../assets/recipes/recipe6.webp";
import recipe7 from "../../assets/recipes/recipe7.webp";
import recipe8 from "../../assets/recipes/recipe8.webp";

export const recipes = [
  {
    id: 1,
    name: "Microgreens and Quinoa Salad",
    image: recipe1, // Replace with the actual path to your image
    ingredients: [
      "1 cup microgreens",
      "1 cup cooked quinoa",
      "1/2 cup cherry tomatoes, halved (red and yellow)",
      "1/2 cucumber, sliced",
      "1/4 cup feta cheese, cubed",
      "1 lemon wedge (for garnish)",
      "2 tbsp balsamic vinaigrette",
    ],
    instructions: [
      "1. Cook the quinoa according to package instructions and let it cool.",
      "2. Halve the cherry tomatoes, slice the cucumber, and cube the feta cheese.",
      "3. In a large bowl, combine the microgreens, cooked quinoa, cherry tomatoes, cucumber slices, and feta cheese.",
      "4. Drizzle the balsamic vinaigrette over the salad and toss gently to combine.",
      "5. Transfer the salad to a serving plate. Garnish with a lemon wedge on the side.",
      "6. Serve immediately and enjoy this fresh, nutritious microgreens and quinoa salad.",
    ],
  },
  {
    id: 2,
    name: "Microgreen Pesto Pasta",
    image: recipe2, // Replace with the actual path to your image
    ingredients: [
      "2 cups microgreens (arugula, basil, or a mix)",
      "1/2 cup grated Parmesan cheese",
      "1/2 cup olive oil",
      "1/3 cup pine nuts",
      "3 cloves garlic, minced",
      "Salt and pepper to taste",
      "1 lb pasta (spaghetti, fettuccine, or your choice)",
      "1/2 cup cherry tomatoes, halved",
      "Lemon wedges (for garnish)",
    ],
    instructions: [
      "1. In a food processor, combine the microgreens, Parmesan cheese, pine nuts, and garlic. Pulse until coarsely chopped.",
      "2. With the processor running, gradually add the olive oil in a steady stream. Process until the mixture is smooth and creamy.",
      "3. Season with salt and pepper to taste.",
      "4. Cook the pasta according to package instructions. Drain and reserve 1/2 cup of the pasta water.",
      "5. In a large bowl, toss the cooked pasta with the pesto sauce. Add reserved pasta water a little at a time until you reach your desired consistency.",
      "6. Transfer the pasta to serving plates. Garnish with halved cherry tomatoes and lemon wedges.",
      "7. Serve immediately and enjoy this fresh, flavorful microgreen pesto pasta.",
    ],
  },
  {
    id: 3,
    name: "Microgreens and Avocado Toast",
    image: recipe3, // Replace with the actual path to your image
    ingredients: [
      "2 slices of bread, toasted",
      "1 ripe avocado",
      "1 cup microgreens",
      "1/2 cup cherry tomatoes, halved",
      "1 tbsp olive oil",
      "Red pepper flakes (optional)",
      "Salt and pepper to taste",
      "Lemon wedge (for garnish)",
    ],
    instructions: [
      "1. Cut the avocado in half, remove the pit, and scoop the flesh into a bowl.",
      "2. Mash the avocado with a fork until smooth. Season with salt and pepper to taste.",
      "3. Spread the mashed avocado evenly over the toasted bread slices.",
      "4. Top with fresh microgreens and halved cherry tomatoes.",
      "5. Drizzle olive oil over the top and sprinkle with red pepper flakes if desired.",
      "6. Serve with a lemon wedge on the side for an extra burst of flavor.",
      "7. Enjoy this fresh and vibrant microgreens and avocado toast.",
    ],
  },
  {
    id: 4,
    name: "Grilled Chicken with Microgreens and Roasted Vegetables",
    image: recipe4, // Replace with the actual path to your image
    ingredients: [
      "2 boneless, skinless chicken breasts",
      "2 cups microgreens",
      "1 bell pepper, sliced",
      "1 zucchini, sliced",
      "1 cup cherry tomatoes, halved",
      "2 tbsp olive oil",
      "1 tsp garlic powder",
      "1 tsp paprika",
      "Salt and pepper to taste",
      "Fresh herbs for garnish (e.g., parsley, thyme)",
      "Lemon wedge (for garnish)",
    ],
    instructions: [
      "1. Preheat the grill to medium-high heat.",
      "2. Season the chicken breasts with garlic powder, paprika, salt, and pepper.",
      "3. Drizzle 1 tbsp of olive oil over the chicken breasts.",
      "4. Place the chicken breasts on the grill and cook for 6-7 minutes on each side, or until the internal temperature reaches 165°F (74°C).",
      "5. Remove from the grill and let them rest for a few minutes before slicing.",
      "6. Preheat the oven to 400°F (200°C).",
      "7. On a baking sheet, toss the bell pepper, zucchini, and cherry tomatoes with the remaining olive oil, salt, and pepper.",
      "8. Roast in the oven for 15-20 minutes, or until the vegetables are tender and slightly caramelized.",
      "9. Arrange the grilled chicken slices on a plate.",
      "10. Add a side of roasted vegetables and a handful of fresh microgreens.",
      "11. Garnish with fresh herbs and a lemon wedge.",
      "12. Serve immediately and enjoy this delicious and healthy grilled chicken with microgreens and roasted vegetables.",
    ],
  },
  {
    id: 5,
    name: "Grilled Beef Steak with Microgreens and Roasted Potatoes",
    image: recipe5, // Replace with the actual path to your image
    ingredients: [
      "2 beef steaks (ribeye, sirloin, or your choice)",
      "2 cups microgreens",
      "4 medium potatoes, cubed",
      "2 tbsp olive oil",
      "2 cloves garlic, minced",
      "1 tbsp fresh rosemary, chopped",
      "Salt and pepper to taste",
      "2 tbsp herb butter (optional)",
      "Fresh herbs for garnish (e.g., parsley, thyme)",
      "Lemon wedge (for garnish)",
    ],
    instructions: [
      "1. Preheat the oven to 400°F (200°C).",
      "2. In a bowl, toss the cubed potatoes with olive oil, minced garlic, chopped rosemary, salt, and pepper.",
      "3. Spread the potatoes on a baking sheet and roast for 25-30 minutes, or until golden brown and crispy.",
      "4. Preheat the grill to medium-high heat.",
      "5. Season the beef steaks with salt and pepper.",
      "6. Grill the steaks for 4-5 minutes on each side, or until desired doneness.",
      "7. If using herb butter, top the steaks with a pat of herb butter during the last minute of grilling.",
      "8. Arrange the grilled beef steaks on a plate.",
      "9. Add a side of roasted potatoes and a handful of fresh microgreens.",
      "10. Garnish with fresh herbs and a lemon wedge.",
      "11. Serve immediately and enjoy this delicious and hearty grilled beef steak with microgreens and roasted potatoes.",
    ],
  },
  {
    id: 6,
    name: "Seared Salmon with Microgreens and Quinoa",
    image: recipe6, // Replace with the actual path to your image
    ingredients: [
      "2 salmon fillets",
      "2 cups microgreens",
      "1 cup quinoa",
      "1 cup cherry tomatoes, halved",
      "2 tbsp olive oil",
      "1 lemon, sliced into wedges",
      "Fresh herbs (e.g., parsley, dill)",
      "Salt and pepper to taste",
    ],
    instructions: [
      "1. Cook the quinoa according to package instructions. Once cooked, fluff with a fork and mix in fresh herbs and halved cherry tomatoes. Season with salt and pepper to taste.",
      "2. Heat 1 tbsp of olive oil in a skillet over medium-high heat.",
      "3. Season the salmon fillets with salt and pepper.",
      "4. Place the salmon fillets skin-side down in the skillet and sear for 4-5 minutes on each side, or until the skin is crispy and the fish is cooked through.",
      "5. Arrange the seared salmon fillets on a plate.",
      "6. Add a side of quinoa and a handful of fresh microgreens.",
      "7. Garnish with lemon wedges and fresh herbs.",
      "8. Serve immediately and enjoy this delicious and healthy seared salmon with microgreens and quinoa.",
    ],
  },
  {
    id: 7,
    name: "Chicken Stir-Fry with Microgreens",
    image: recipe7, // Replace with the actual path to your image
    ingredients: [
      "2 boneless, skinless chicken breasts, sliced thin",
      "2 cups microgreens",
      "1 bell pepper, sliced",
      "1 cup broccoli florets",
      "1 cup snap peas",
      "2 tbsp soy sauce",
      "1 tbsp hoisin sauce",
      "1 tbsp sesame oil",
      "2 cloves garlic, minced",
      "1 tbsp ginger, minced",
      "1 tbsp sesame seeds",
      "2 green onions, sliced",
      "Salt and pepper to taste",
    ],
    instructions: [
      "1. Slice the chicken breasts thinly.",
      "2. Slice the bell pepper and chop the broccoli into florets.",
      "3. Mince the garlic and ginger.",
      "4. Heat the sesame oil in a large skillet or wok over medium-high heat.",
      "5. Add the sliced chicken and cook until browned and cooked through, about 5-7 minutes.",
      "6. Remove the chicken from the skillet and set aside.",
      "7. In the same skillet, add the garlic and ginger and sauté for 1 minute.",
      "8. Add the bell pepper, broccoli, and snap peas. Stir-fry for 3-4 minutes until the vegetables are tender-crisp.",
      "9. Return the cooked chicken to the skillet.",
      "10. Add the soy sauce and hoisin sauce. Stir to coat the chicken and vegetables evenly.",
      "11. Season with salt and pepper to taste.",
      "12. Arrange the chicken stir-fry on a plate.",
      "13. Add a side of fresh microgreens.",
      "14. Garnish with sesame seeds and sliced green onions.",
      "15. Serve immediately with a pair of chopsticks and enjoy this delicious and healthy chicken stir-fry with microgreens.",
    ],
  },
  {
    id: 8,
    name: "Shrimp Avocado Salad with Microgreens",
    image: recipe8, // Replace with the actual path to your image
    ingredients: [
      "12 large shrimp, peeled and deveined",
      "1 avocado, diced",
      "1 cup cherry tomatoes, halved",
      "1/2 red onion, thinly sliced",
      "2 cups microgreens",
      "1/4 cup feta cheese, crumbled",
      "2 tbsp olive oil",
      "1 tbsp lemon juice",
      "1 tsp Dijon mustard",
      "1 clove garlic, minced",
      "Salt and pepper to taste",
      "Fresh herbs for garnish (e.g., parsley, dill)",
    ],
    instructions: [
      "1. Heat 1 tbsp of olive oil in a skillet over medium-high heat.",
      "2. Season the shrimp with salt and pepper.",
      "3. Cook the shrimp for 2-3 minutes on each side until they are pink and opaque. Remove from heat and set aside.",
      "4. In a small bowl, whisk together the lemon juice, remaining olive oil, Dijon mustard, minced garlic, salt, and pepper.",
      "5. In a large bowl, combine the diced avocado, cherry tomatoes, red onion, and microgreens.",
      "6. Add the cooked shrimp to the bowl.",
      "7. Drizzle the lemon vinaigrette over the salad and toss gently to combine.",
      "8. Transfer the salad to serving plates.",
      "9. Sprinkle with crumbled feta cheese and fresh herbs.",
      "10. Serve immediately and enjoy this fresh and vibrant shrimp avocado salad with microgreens.",
    ],
  },
];

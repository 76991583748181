import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Heading,
  Text,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SubscriptionPage = () => {
  const navigate = useNavigate();
  return (
    <Container maxW="container.xl" p={4}>
      <Box textAlign="center" py={10} px={6}>
        <Heading as="h1" size="2xl" mb={4} color="green.800">
          MICROGREENS SUBSCRIPTION OFFER
        </Heading>
        <Heading as="h2" size="lg" mb={4} color="green.600">
          JOIN OUR MICROGREENS SUBSCRIPTION
        </Heading>
        <Text fontSize="xl" mb={4}>
          - Enjoy discounts on all your favorite microgreens <br />
          - Experience a fresh variety each week <br />
          - Access our exclusive recipe content <br />- Pause or cancel anytime
          at no extra cost!
        </Text>
        <Text fontSize="md" mb={6}>
          Place your subscription order by midnight Sunday to receive your
          products on Thursday. Orders placed between Monday and Thursday will
          be delivered the following Thursday.
        </Text>
      </Box>

      <Grid
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        gap={6}
        mb={10}
      >
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          bg="white"
          p={6}
        >
          <Image
            src="/path-to-your-image-1.jpg"
            alt="Micro Quartet Subscription"
            mb={4}
          />
          <Heading as="h3" size="md" mb={2} color="green.700">
            Micro Quartet Subscription
          </Heading>
          <Text mb={4}>$575.00 EVERY 12 MONTHS</Text>
          <Button
            colorScheme="green"
            onClick={() => navigate("/subscription/micro-quartet")}
          >
            Subscribe Now
          </Button>
        </Box>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          bg="white"
          p={6}
        >
          <Image
            src="/path-to-your-image-2.jpg"
            alt="Six Shades of Green Subscription"
            mb={4}
          />
          <Heading as="h3" size="md" mb={2} color="green.700">
            Six Shades of Green Subscription
          </Heading>
          <Text mb={4}>$33.50 EVERY 2 WEEKS</Text>
          <Button
            colorScheme="green"
            onClick={() => navigate("/subscription/six-shades")}
          >
            Subscribe Now
          </Button>
        </Box>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          bg="white"
          p={6}
        >
          <Image
            src="/path-to-your-image-3.jpg"
            alt="Trio of Tastes Subscription"
            mb={4}
          />
          <Heading as="h3" size="md" mb={2} color="green.700">
            Trio of Tastes Subscription
          </Heading>
          <Text mb={4}>$28.00 EVERY 2 WEEKS</Text>
          <Button
            colorScheme="green"
            onClick={() => navigate("/subscription/trio-of-tastes")}
          >
            Subscribe Now
          </Button>
        </Box>
      </Grid>

      <Text fontSize="sm" textAlign="center" color="gray.600">
        Due to the nature of microgreens, orders are subject to availability,
        and delivery/pickup times may be impacted by the growing cycle of each
        product. Please{" "}
        <Button
          variant="link"
          colorScheme="green"
          onClick={() => navigate("/contact")}
        >
          contact us
        </Button>{" "}
        if you have any questions before purchase.
      </Text>
    </Container>
  );
};

export default SubscriptionPage;

import { useState } from "react";
import { Box, IconButton, Image, useMediaQuery } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import momo from "../assets/momo-logo.png";
import Banner from "./Banner"; // Import the Banner component

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  background-color: gray.800;
  z-index: 1;
`;

const Header = () => {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [showBanner, setShowBanner] = useState(true);

  const closeBanner = () => {
    setShowBanner(false);
  };

  return (
    <HeaderWrapper>
      {isMobile && (
        <ButtonWrapper>
          <IconButton
            position={"absolute"}
            bottom={0}
            margin={1}
            icon={<HamburgerIcon boxSize={7} />}
            onClick={() =>
              window.location.pathname === "/menu"
                ? window.history.back()
                : navigate("/menu" /*  */)
            }
          />
        </ButtonWrapper>
      )}
      {showBanner && <Banner onClose={closeBanner} />}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="200px"
        margin={isMobile ? "auto" : "right"}
        marginTop={showBanner ? "70px" : "0"}
        paddingTop={showBanner && isMobile ? "70px" : "0"}
        transition="margin-top 0.3s ease"
      >
        <Image
          boxSize={["170px", null, "170"]}
          objectFit="cover"
          alt="momo microgreens"
          src={momo}
        />
      </Box>
    </HeaderWrapper>
  );
};

export default Header;

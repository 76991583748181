import {
  Center,
  Container,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import booth from "../assets/booth.jpg";

const Home = () => (
  <Stack margin="2em 1em" spacing={6}>
    <Heading as="h3" size="md" textAlign="center">
      Providing Fresh and Nutrient-Packed Microgreens for a Healthier You!
    </Heading>
    <Center>
      <Image
        src={booth}
        w="95%"
        h={["300px", null, "500"]}
        objectFit="scale-down"
        align="center"
      />
    </Center>
    <Container maxW="container.md">
      <Text fontSize="lg">
        At Momo Microgreens, we believe in the power of nature to nourish and
        revitalize. Our carefully cultivated microgreens are packed with
        essential nutrients, bursting with flavor, and a perfect addition to
        your daily meals.
      </Text>
    </Container>
  </Stack>
);

export default Home;

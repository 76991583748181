import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  List,
  ListItem,
  Box,
  Image,
} from "@chakra-ui/react";

const RecipeModal = ({ isOpen, onClose, recipe }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{recipe.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Image
              src={recipe.image}
              alt={recipe.name}
              mb={4}
              borderRadius="md"
            />
            <Text fontWeight="bold">Ingredients:</Text>
            <List spacing={2}>
              {recipe.ingredients.map((ingredient, index) => (
                <ListItem key={index}>{ingredient}</ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <Text fontWeight="bold">Instructions:</Text>
            <List spacing={2}>
              {recipe.instructions.map((instruction, index) => (
                <ListItem key={index}>{instruction}</ListItem>
              ))}
            </List>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RecipeModal;

import { Box, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
import { Route, Routes, useNavigate } from "react-router-dom";
import MomoMenu from "./MomoMenu";
import Header from "./Header";
import Home from "../pages/Home";
import Products from "../pages/Products";
import ContactUs from "../pages/Contact";
import AboutUs from "../pages/AboutUs";
import NoPage from "../pages/NoPage";
import SlideInPage from "./SlideInPage";
import SubscriptionPage from "../pages/Subscription";
import RecipePage from "../pages/RecipePage";

const Layout = () => {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  if (window.location.pathname === "/menu" && !isMobile) {
    //navigate to home because we don't want the menu displaying in main content
    navigate("/");
  }

  const currentYear = new Date().getFullYear();

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh" // Set minimum height to 100% of viewport height
    >
      <Grid
        templateAreas={[
          `"header"
            "main"
            "footer"`,
          null,
          `"header header"
            "nav main"
            "nav footer"`,
        ]}
        gridTemplateRows={["auto 1fr 30px", null, "auto 1fr 30px"]} // Change the first row to "auto" to adjust its height based on content
        gridTemplateColumns={["1fr", null, "200px 1fr"]}
        fontWeight="bold"
        flex="1"
      >
        <GridItem
          bg="blue.200"
          area={"header"}
          position={"static"}
          width="100%"
          zIndex={1}
        >
          <Header />
        </GridItem>
        <GridItem bg="blue.200" area={"nav"} display={["none", null, "block"]}>
          <MomoMenu />
        </GridItem>
        <GridItem
          padding={["10px", null, "12px"]}
          area={"main"}
          bgColor={"blue.100"}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="products" element={<Products />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="*" element={<NoPage />} />
            <Route path="subscription" element={<SubscriptionPage />} />
            <Route path="recipes" element={<RecipePage />} />
            <Route path="our-story" element={<AboutUs />} />
            <Route
              path="/menu"
              element={
                <SlideInPage>
                  <MomoMenu />
                </SlideInPage>
              }
            />
          </Routes>
        </GridItem>
        <GridItem
          bg="blue.200"
          display="flex"
          alignItems="center"
          justifyContent="center"
          area={"footer"}
        >
          © {currentYear} Momo Microgreens
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Layout;

import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Menu, MenuButton } from "@chakra-ui/react";

const MenuWrapper = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #e8f5e9; /* Light green background */
  border-radius: 10px;
  @media screen and (max-width: 767px) {
    padding: 10px;
    .h3 {
      font-size: 2.2em;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: #388e3c; /* Darker green */
  color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  width: 100%; /* Full width for desktop view */
  &:hover {
    background-color: #2e7d32; /* Darker shade on hover */
  }

  @media screen and (max-width: 767px) {
    max-width: 90%; /* Max width for mobile view */
    width: 90%; /* Consistent width for mobile view */
    margin: 0 auto; /* Center the buttons */
  }
`;

const MomoMenu = () => {
  const navigate = useNavigate();
  return (
    <MenuWrapper>
      <Grid
        templateRows="repeat(5, 1fr)"
        templateColumns="repeat(1, 1fr)"
        gap={4}
      >
        <Menu>
          <MenuButton as={StyledButton} onClick={() => navigate("/products")}>
            Products
          </MenuButton>
        </Menu>
        <Menu>
          <MenuButton
            as={StyledButton}
            onClick={() => navigate("/subscription")}
          >
            Subscription
          </MenuButton>
        </Menu>
        <Menu>
          <MenuButton as={StyledButton} onClick={() => navigate("/recipes")}>
            Recipes
          </MenuButton>
        </Menu>
        <Menu>
          <MenuButton as={StyledButton} onClick={() => navigate("/our-story")}>
            Our Story
          </MenuButton>
        </Menu>
        <Menu>
          <MenuButton as={StyledButton} onClick={() => navigate("/contact")}>
            Contact
          </MenuButton>
        </Menu>
      </Grid>
    </MenuWrapper>
  );
};

export default MomoMenu;

import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  Grid,
  Heading,
  Input,
  Text,
  Textarea,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://t0lacfxk19.execute-api.us-east-2.amazonaws.com/dev/send",
        formData
      ); // Replace with your API Gateway endpoint
      console.log(response.data);
      alert("Your message has been sent successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("There was an error sending your message!", error);
    }
  };

  return (
    <Box minHeight="100vh" py={10}>
      <Container maxW="container.xl">
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={10}>
          <Box>
            <Heading as="h1" size="2xl" mb={4} color="black">
              WE WANT TO HEAR FROM YOU.
            </Heading>
            <Text fontSize="lg" mb={6}>
              Reach out and a member of our team will direct your inquiry to the
              appropriate contact at Momo Microgreens.
            </Text>
          </Box>
          <Box bg="white" color="black" p={8} borderRadius="md" boxShadow="lg">
            <form onSubmit={handleSubmit}>
              <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
                <FormControl isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <FormControl isRequired mb={4}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired mb={6}>
                <FormLabel>Message</FormLabel>
                <Textarea
                  name="message"
                  placeholder="Your message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </FormControl>
              <Button colorScheme="green" type="submit" size="lg" width="full">
                SEND
              </Button>
            </form>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUsPage;

import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import RecipeGrid from "../components/recipes/RecipeGrid";

const RecipePage = () => (
  <Box p={4}>
    <Heading as="h1" mb={6} textAlign="center">
      Microgreens Recipes
    </Heading>
    <RecipeGrid />
  </Box>
);

export default RecipePage;

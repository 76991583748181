import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const SlideInPage = (props) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    // Use a setTimeout to delay the animation start (adjust the timing as needed)
    setIsAnimated(true);
  }, []);

  return (
    <Box
      marginTop={["50px", null, "180px"]}
      position="absolute"
      left={isAnimated ? "0%" : "-100%"} /* Start off-screen to the left */
      width="100%"
      zIndex="99999999999999"
      transition="left 0.3s ease" /* Add a transition for smooth animation */
      bgColor="rgba(255, 255, 255, 0.1)" // Slight white background to enhance the effect
      backdropFilter="blur(10px)" // Blurs the background content behind the box
    >
      {props.children}
    </Box>
  );
};

export default SlideInPage;

import { Grid } from "@chakra-ui/react";
import ProductItem from "./ProductItem";

const ProductGrid = ({ products }) => {
  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(auto-fill, minmax(322px, 1fr))",
      }}
      gap={15}
    >
      {products.map((product) => (
        <ProductItem key={product.id} product={product} />
      ))}
    </Grid>
  );
};

export default ProductGrid;

import { Container, Heading, Stack, Text } from "@chakra-ui/react";

const AboutUs = () => (
  <Stack marginTop="2em" alignItems="center" spacing={3}>
    <Heading as="h1" mb="4">
      About Momo Microgreens
    </Heading>
    <Container maxW="container.md">
      <Text mb="4">
        Welcome to Momo Microgreens, where health meets homegrown goodness. Our
        microgreens are nurtured with care right here in the heart of St. Marys,
        a place we're proud to call home.
      </Text>
      <Text mb="4">
        What sets us apart? We believe in the power of organic, locally-sourced
        produce. That's why we've dedicated ourselves to growing microgreens
        that are not only good for you, but good for our community too.
      </Text>
      <Text mb="4">
        Momo Microgreens is more than a business; it's a family affair. Meet the
        faces behind the greens: Ram Laxmi, the green-thumbed matriarch with a
        passion for growing; Tyler Campbell, her partner in both life and
        business, and the man with the greenest thumb you'll ever find; and Neo
        Campbell, the next generation of microgreen enthusiasts, learning the
        ropes from the very best.
      </Text>
      <Text mb="4">
        Together, we bring you the freshest, most vibrant microgreens, straight
        from our family to yours. Every tray we grow is a testament to our
        commitment to quality, sustainability, and the joy of nurturing life
        from seed to table.
      </Text>
      <Text>
        Thank you for being a part of our journey. We invite you to explore our
        selection and taste the difference of microgreens grown with love.
      </Text>
    </Container>
  </Stack>
);

export default AboutUs;
